import { createApp } from 'vue'
import DefaultTemplate from './templates/Default'
import router from './router'
import vueTheStorages from 'vue-the-storages'

let app = createApp(DefaultTemplate)
app.use(router)
    .use(vueTheStorages)

app.mount('#app')
