import ArticlesRoute from './../modules/article/router'
import UsersRoute from './../modules/user/router'

const Dashboard = () => import('../views/Home.vue')
const Reports = () => import('../views/Reports.vue')

export default [
   {
      path: '',
      component: () => import('@/templates/Dashboard'),
      children: [
         {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
            children: [
               {
                  path: 'reports',
                  name: 'dashReports',
                  alias: '/dashboard',
                  component: Reports
               },
               ...ArticlesRoute,
               ...UsersRoute
            ]
         }
      ]
   }
]
