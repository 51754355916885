import { createRouter, createWebHistory } from 'vue-router'
import AuthRoutes from '@/modules/auth/router'
import DashboardRoutes from '@/modules/dashboard/router'
import About from '../views/About.vue'
import Profile from '@/views/Profile.vue'
import Invoice from '@/views/transactions/Invoice.vue'
import InvoiceDetail from '@/views/transactions/InvoiceDetail.vue'

const routes = [
   { path: '', redirect: '/login' },
   ...AuthRoutes,
   ...DashboardRoutes,
   { path: '/about', name: 'About', component: About },
   { path: '/profile', name: 'Profile', component: Profile },
   { path: '/my-invoices', name: 'Invoice', component: Invoice },
   { path: '/my-invoices/:name', name: 'InvoiceDetail', component: InvoiceDetail }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

let getToken = ()=>{
    try {
        return JSON.parse(localStorage.frappUser).token;
    } catch (e) {
        return null
    }
}

export default router
